// lenis
const lenis = new Lenis({
  lerp: 0.06,
});

function raf(time) {
  lenis.raf(time);
  requestAnimationFrame(raf);
}

requestAnimationFrame(raf);

lenis.on("scroll", ScrollTrigger.update);

gsap.ticker.add((time) => {
  lenis.raf(time * 1000);
});

jQuery(document).ready(function ($) {
  if (navigator.userAgent.indexOf("Mac") > 0 || navigator.userAgent.indexOf("iPhone") > 0) {
    $("body").addClass("mac-os");
  }

  // in-view detect
  !(function () {
    const blocks = document.querySelectorAll("h1, h2, h3, .h3, .in-view-detect, .fade-in-wrapper > *");

    [].forEach.call(blocks, ($item) => {
      function onScroll() {
        let delta = $item.getBoundingClientRect().top - window.innerHeight <= ($item.offsetHeight * -1) / 4 && !$item.classList.contains("in-view");

        if (delta) {
          setTimeout(() => {
            $item.classList.remove("in-view-detect");
            $item.classList.add("in-view");
          }, 200);
        }
      }
      onScroll();

      lenis.on("scroll", (e) => {
        onScroll();
      });
    });
  })();

  !(function () {
    const container = document.querySelector(".steps");
    if (!container) return;

    const itemLast = document.querySelector(".steps .steps-item:last-child");
    let stepLast = itemLast.clientHeight / 2 + "px";
    if (window.innerWidth < 768) {
      stepLast = itemLast.clientHeight + "px";
    }
    document.querySelector(".steps-wrapper").setAttribute("style", `--stepLast:${stepLast}`);

    ScrollTrigger.create({
      trigger: container,
      start: "top bottom",
      end: () => "+=" + container.offsetHeight,
      onUpdate: updateValues,
    });
    function updateValues() {
      const wrapper = document.querySelector(".steps");
      let rowItems = [...wrapper.querySelectorAll(".steps-item")];

      rowItems.forEach((item) => {
        if (ScrollTrigger.positionInViewport(item, "center") < 0.8) {
          item.classList.add("active");
        } else {
          let arr = rowItems.slice(rowItems.indexOf(item));
          arr.forEach((el) => {
            el.classList.remove("active");
          });
        }
        const activeItems = wrapper.querySelectorAll(".steps-item.active");
        const activeItem = activeItems[activeItems.length - 1];
        if (!activeItems.length) {
          wrapper.setAttribute("style", "--stepHeight:0px");
          return;
        }
        let lineHeight = rowItems.indexOf(activeItem) === rowItems.length - 1 ? "100%" : activeItem.offsetTop + activeItem.offsetHeight / 2 + "px";
        wrapper.setAttribute("style", `--stepHeight:${lineHeight}`);
      });
    }
    updateValues();
  })();

  // banner parallax
  gsap.utils.toArray(".parallax-lg").forEach((section, i) => {
    gsap.to(section, {
      backgroundPosition: `50% ${innerHeight / 9}px`,
      ease: "none",
      scrollTrigger: {
        trigger: section,
        scrub: true,
        start: "top top",
        end: "bottom top",
      },
    });
  });

  // btn-fixed
  ScrollTrigger.create({
    trigger: ".block-cta",
    start: "top bottom",
    onEnter: function () {
      $(".btn-fixed").addClass("is-hidden");
    },
    onLeaveBack: function () {
      $(".btn-fixed").removeClass("is-hidden");
    },
  });

  // swiper gallery
  if ($(".block-gallery").length) {
    const gallerySlider = new Swiper(".block-gallery .swiper", {
      loop: true,
      spaceBetween: 16,
      initialSlide: 1,
      slidesPerView: 1.32,
      centeredSlides: true,
      speed: 1000,
      autoplay: {
        delay: 6000,
        disableOnInteraction: false,
      },
      navigation: {
        nextEl: ".block-gallery .swiper-button-next",
        prevEl: ".block-gallery .swiper-button-prev",
      },
      breakpoints: {
        768: {
          initialSlide: 0,
          centeredSlides: false,
          slidesPerView: 4,
          spaceBetween: 16,
        },
        1024: {
          initialSlide: 0,
          centeredSlides: false,
          slidesPerView: 4,
          spaceBetween: 32,
        },
      },
      on: {
        init: function () {
          ScrollTrigger.refresh();
        },
      },
    });
  }

  //header
  var header = document.querySelector(".header");
  var headroom = new Headroom(header);
  headroom.init();

  function navOpen() {
    $(".navbar-toggler").addClass("active");
    $(".header").addClass("nav-opened");
    $(".navigation-main").addClass("active");
    headroom.freeze();
    lenis.stop();
  }

  function navClose() {
    $(".navbar-toggler").removeClass("active");
    setTimeout(() => {
      $(".header").removeClass("nav-opened");
    }, 300);
    $(".navigation-main").removeClass("active");
    headroom.unfreeze();
    lenis.start();
  }

  $(".header .navbar-toggler").on("click", function () {
    if ($(this).hasClass("active")) {
      navClose();
    } else {
      navOpen();
    }
  });

  // waypoints
  $(".section").waypoint(
    function (direction) {
      if (direction === "down") {
        $(".navigation-main .active").removeClass("active");
        var selector = ".navigation-main a[href='#" + this.element.id + "']";
        $(selector).parent().addClass("active");
      }
    },
    {
      offset: 0,
    }
  );
  $(".section").waypoint(
    function (direction) {
      if (direction === "up") {
        $(".navigation-main .active").removeClass("active");
        var selector = ".navigation-main a[href='#" + this.element.id + "']";
        $(selector).parent().addClass("active");
      }
    },
    {
      offset: -100,
    }
  );

  $(".navigation-main a").on("click", function (e) {
    e.preventDefault();
    navClose();
    var dest = document.querySelector($(this).attr("href"));
    console.log(dest);
    lenis.scrollTo(dest, { duration: 1 });
  });
  $(".footer-menu a").on("click", function (e) {
    e.preventDefault();
    var dest = document.querySelector($(this).attr("href"));
    lenis.scrollTo(dest, { duration: 1 });
  });
  $(".btn-anchor").on("click", function (e) {
    e.preventDefault();
    var dest = document.querySelector($(this).attr("href"));
    lenis.scrollTo(dest, { duration: 1, offset: 0 });
  });

  // headlines
  var splits = document.querySelectorAll("h1, h2");

  splits.forEach((txt) => {
    const text = SplitType.create(txt);

    txt.style.visibility = "visible";
  });

  document.querySelectorAll("h3").forEach((txt) => {
    const text = SplitType.create(txt);

    txt.style.visibility = "visible";

    gsap.to(text.chars, {
      stagger: 0.1,
      opacity: 1,
      scrollTrigger: {
        pinType: "fixed",
        trigger: txt,
        start: "top 75%",
        end: "bottom 25%",
        scrub: 1,
      },
    });
  });

  let mm = gsap.matchMedia();
  mm.add("(max-width: 1023px)", () => {
    document.querySelectorAll(".block-sustainability .h3").forEach((txt) => {
      const text = SplitType.create(txt);
      txt.style.visibility = "visible";
      gsap.to(text.chars, {
        stagger: 0.1,
        opacity: 1,
        scrollTrigger: {
          pinType: "fixed",
          trigger: txt,
          start: "top 75%",
          end: "bottom 25%",
          scrub: 1,
        },
      });
    });
  });
  mm.add("(min-width: 1024px)", () => {
    document.querySelectorAll(".block-sustainability .h3").forEach((txt) => {
      const text = SplitType.create(txt);
      txt.style.visibility = "visible";
      gsap.to(text.chars, {
        stagger: 0.1,
        opacity: 1,
        scrollTrigger: {
          trigger: ".block-sustainability .wrapper",
          pin: ".block-sustainability .text",
          start: "top top",
          end: () => "+=" + (document.querySelector(".block-sustainability .wrapper").clientHeight - window.innerHeight),
          scrub: 1,
        },
      });
    });
  });

  let parallaxAmount = window.innerHeight * 0.03;
  if (window.innerWidth < 1024) {
    parallaxAmount = window.innerHeight * 0.01;
  }
  document.querySelectorAll(".img-parallax").forEach((img) => {
    gsap.set(img, {
      y: -parallaxAmount,
    });
    gsap.to(img, {
      y: parallaxAmount,
      scrollTrigger: {
        trigger: img,
        start: "top bottom",
        end: "bottom top",
        scrub: 1,
      },
    });
  });

  $('[data-fancybox="gallery"]').each(function () {
    if ($(this).closest(".image-animated").css("display") === "none") {
      $(this).addClass("is-hidden");
    }
  });
  var fancy = $('[data-fancybox="gallery"]:not(.is-hidden)').fancybox({
    infobar: false,
    hideScrollbar: false,
    loop: true,
    buttons: ["close"],
  });

  $("body").imagesLoaded(function () {
    ScrollTrigger.refresh();
  });
});
